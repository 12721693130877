import React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import {Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import messages from '../images/messages.svg';
import promotion from '../images/promotion.svg';
import wheel from '../images/wheel.svg';
import winner from '../images/winner.svg';
import {FaQuoteLeft, FaRegCheckCircle, FaMedal} from "react-icons/fa";
import Seo from "../components/seo";
import FaqAccordion from "../components/faq-accordion";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Instagram Giveaway Winner Picker | Generate Random Winners"
        description="Pick a winner from likes, comments, or hashtags on Instagram. Build the trust of your followers by generating a truly random winner for your Instagram giveaway."
        lang="en"
        canonical={data.site.siteMetadata.siteUrl}
        link={[
	        { rel: "alternate", hreflang: "en", href: data.site.siteMetadata.siteUrl },
	        { rel: "alternate", hreflang: "ru", href: `${data.site.siteMetadata.siteUrl}/ru/` },
	        { rel: "alternate", hreflang: "x-default", href: data.site.siteMetadata.siteUrl },
        ]}
      />
      <section className="pt-5 bg-blob">
        <Container>
          <Row className="text-white align-items-center">
            <Col xs="12" md="6">
              <h1 className="font-weight-bold">
                Instagram Giveaway Winner Picker
              </h1>
              <p className="mb-5">
                A powerful tool to pick random winners for your <b>Instagram</b> giveaways, sweepstakes, or competitions. Save your time on collecting and validating thousands of entries from comments, likes, followers, or hashtags.
              </p>
              <Button size="lg" variant="danger" as="a" href="https://app.arbitery.com/?a=1" data-event="start-now" data-id="introduction-button">Start Now</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center pt-5">
              <Img className="w-100" fluid={data?.andTheWinners?.childImageSharp?.fluid} alt="Instagram Giveaway Winner Picker"/>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442.7449 182.91" style={{ position: 'relative', top: '-1px' }}>
          <path
            d="m 0,182.91 48.046,-31.994 c 48.047,-31.995 144.14,-95.984 240.233,-95.984 96.093,0 192.186,63.989 288.279,69.288 96.094,5.699 192.187,-48.291 288.28,-53.291 96.093,-4.999 192.186,36.994 288.279,37.294 96.093,-0.3 192.187,-42.293 240.233,-63.989 L 1442.745,23.57 V 0 H 0 Z"
            fill="#5c7cbb"/>
        </svg>
      </section>
      <section className="py-5" id="features-section">
        <Container>
          <h2 className="text-center pb-5">Everything You Need to Pick a Winner</h2>
          <Row>
            <Col xs="12" sm="6" lg="3">
              <div className="text-center mb-3">
                <img className="text-primary" width="64" height="64" src={wheel} alt="Random Winner Generator"/>
              </div>
              <h3 className="h5">Random Winner Generator</h3>
              <p>
                Select winners from likes, mentions, comments, or hashtags on Instagram. You can also combine multiple rules.
              </p>
            </Col>
            <Col xs="12" sm="6" lg="3">
              <div className="text-center mb-3">
                <img className="text-primary" width="64" height="64" src={promotion} alt="Announce a Winner"/>
              </div>
              <h3 className="h5">Announcement Template</h3>
              <p>
                Use a customizable template to create an Instagram announcement post and a webpage with a unique URL to share across your social networks.
              </p>
            </Col>
            <Col xs="12" sm="6" lg="3">
              <div className="text-center mb-3">
                <img className="text-primary" width="64" height="64" src={winner} alt="Certificate of Validity"/>
              </div>
              <h3 className="h5">Certificate of Validity</h3>
              <p>
                Generate a certificate that indicates the names of the winners and alternates. This certificate guarantees the transparency of your sweepstakes.
              </p>
            </Col>
            <Col xs="12" sm="6" lg="3">
              <div className="text-center mb-3">
                <img className="text-primary" width="64" height="64" src={messages} alt="Contact the Winners"/>
              </div>
              <h3 className="h5">Prize Claiming</h3>
              <p>
                Collect contact information and verify eligibility to award prizes with our "Claim Your Prize" system.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-light">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" md="6" lg="7" className="d-flex justify-content-center mb-5 mb-md-0">
              <Img className="w-100" fluid={data?.commentPicker?.childImageSharp?.fluid} alt="Instagram Comment Picker"/>
            </Col>
            <Col xs="12" md="6" lg="5">
              <h2>Instagram Comment Picker</h2>
              <p>
                Are you running a contest where people can enter by posting a comment or liking your post?
                To make your life easier, you will need a tool that can automatically generate a winner by picking a random Instagram comment.
              </p>
              <p className="mt-4">
                <Button data-event="start-now" data-id="random-comment-picker" as="a" size="lg" href="https://app.arbitery.com/?a=1">Get Started</Button>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Card className="shadow-sm p-3">
            <Card.Body>
              <Row className="align-items-center">
                <Col xs="12" lg="9">
                  <div className="h3">
                    Try Arbitery Risk-Free!
                  </div>
                  <p>
                    We offer a 30-day money-back guarantee. If you are not 100% satisfied with Arbitery, we'll refund your payment. No hassle, no risk.
                  </p>
                </Col>
                <Col xs="12" lg="3">
                  <Button data-event="start-now" data-id="try-risk-free" as="a" size="lg" href="https://app.arbitery.com/?a=1">Try Now Risk-Free</Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
      <section className="pt-5 bg-light">
        <Container>
          <Row className="align-items-end">
            <Col xs="12" md="6" lg="5" className="d-flex justify-content-center px-5 mb-3 mb-md-0">
              <Img className="w-100" fluid={data?.announcement?.childImageSharp?.fluid} alt="Winner announcement post" style={{ maxWidth: '300px'}}/>
            </Col>
            <Col xs="12" md={{ order: 'first', span: 6 }} lg="7" className="align-self-center">
              <h2>Announcement Post</h2>
              <p>
                You’ve found your contest winners, but how do you contact them? Arbitery enables you to create a customizable webpage to announce the results of your contest. The "Claim Your Prize" feature allows you to easily collect contact information making it easy for users with private accounts to participate.
              </p>
              <p className="mt-4">
                <Button data-event="start-now" data-id="announce-winner" as="a" size="lg" href="https://app.arbitery.com/?a=1">Get Started</Button>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5" id="how-it-works-section">
        <Container>
          <h2 className="text-center pb-5">How Does It Work?</h2>
          <Row>
            <Col xs="12" md="4">
              <h3 className="h5">1. Entry Criteria</h3>
              <p>
                Provide the entry criteria and the number of winners of your Instagram giveaway or contest.
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h5">2. Winner Selection</h3>
              <p>
                Our giveaway picker tool automatically collects entrants based on your criteria and randomly selects a winner.
              </p>
            </Col>
            <Col xs="12" md="4">
              <h3 className="h5">3. Announcement</h3>
              <p>
                Create an announcement post to share the chosen winners on your Instagram feed.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <h2 className="text-center pb-5">What Our Customers Think</h2>
          <Row>
            <Col xs="12" md="6" className="mb-5">
              <Card border="light" className="shadow position-relative">
                <FaQuoteLeft className="position-absolute text-warning quotes-icon" />
                <Card.Body>
                  <p>
                    Arbitery provides a great set of tools that enables you to run your Instagram sweepstakes transparently. It saved us an enormous number of hours of manual work collecting and validating the participants. If I have to do a promotion, I will do it with this platform.
                  </p>
                  <div className="d-flex align-items-center">
                    <Img fixed={data?.faceM?.childImageSharp?.fixed} className="mr-2 rounded" alt="Arbitery customer review Jason G." />
                    <b className="text-secondary">Jason G.</b>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <Card border="light" className="shadow position-relative">
                <FaQuoteLeft className="position-absolute text-warning quotes-icon" />
                <Card.Body>
                  <p>
                    Selecting a winner for your Instagram contest has never been easier. I don't have to deal with annoying spreadsheets anymore. Many more people are willing to participate when they know that you aren't simply selecting your best friend as the winner.
                  </p>
                  <div className="d-flex align-items-center">
                    <Img fixed={data?.faceF?.childImageSharp?.fixed} className="mr-2 rounded" alt="Arbitery customer review Jennifer M." />
                    <b className="text-secondary">Jennifer M.</b>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 bg-light">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" md="6" lg="7" className="align-self-center">
              <div className="display-3 mb-3"><FaMedal className="text-primary" /></div>
              <h2>Run Successful Instagram Contests</h2>
              <p>
                Host effective contests and giveaways for your business or clients. Our random selection tool will automatically find all the entries and generate a random name.
              </p>
            </Col>
            <Col xs="12" md="6" lg="5" className="d-flex align-self-end justify-content-center mt-3 mt-md-0">
              <ul className="list-unstyled">
                <li className="d-flex align-items-center h5 font-weight-light"><FaRegCheckCircle className="text-success mr-3" /> Choose a random follower</li>
                <li className="d-flex align-items-center h5 font-weight-light"><FaRegCheckCircle className="text-success mr-3" /> Filter out unwanted entries</li>
                <li className="d-flex align-items-center h5 font-weight-light"><FaRegCheckCircle className="text-success mr-3" /> Run multi post IG giveaways</li>
                <li className="d-flex align-items-center h5 font-weight-light"><FaRegCheckCircle className="text-success mr-3" /> Pick a random comment</li>
                <li className="d-flex align-items-center h5 font-weight-light"><FaRegCheckCircle className="text-success mr-3" /> Combine multiple rules</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5" id="faq">
        <Container>
          <div className="text-center pb-3">
            <h2>Frequently Asked Questions (FAQs)</h2>
            <p>Do you have a question about Arbitery? See the list below for our most frequently asked questions.</p>
          </div>
          <Row>
            <Col xs="12" lg="8" className="mx-auto mb-3">
              <FaqAccordion />
            </Col>
          </Row>
          <div className="text-center">
            <p className="text-muted">Have a question that isn't listed here? <a href="https://go.crisp.chat/chat/embed/?website_id=f793a958-55b5-4cd0-ab49-a88e01cb8382">Get in touch!</a></p>
          </div>
        </Container>
      </section>
      <section className="py-5" id="pricing-section">
        <Container>
          <div className="text-center">
            <h2>Choose Your Plan</h2>
            <p>
              Upgrade, downgrade, or cancel any time. If you're not 100% satisfied, let us know within the first 30 days for a full refund!
            </p>
          </div>
          <Row className="align-items-stretch">
            {/*<Col xs="12" md="6" lg="4" className="mx-auto mt-5 align-self-stretch">*/}
            {/*  <Card bg="light" className="shadow-sm h-100">*/}
            {/*    <Card.Body className="text-center">*/}
            {/*      <h3 className="font-weight-light">Free</h3>*/}
            {/*      <p className="h3 my-4">$0</p>*/}
            {/*      <p className="text-center">*/}
            {/*        <Button data-event="start-now" data-id="free-plan" as="a" href="https://app.arbitery.com/?a=1" size="lg">Start Now</Button>*/}
            {/*      </p>*/}
            {/*      <p><small>Sign up and start using for free</small></p>*/}
            {/*      <hr className="mt-5 mb-5" />*/}
            {/*      <p><b>Free</b> Instagram comment picker</p>*/}
            {/*      <p><b>Free</b> Instagram like picker</p>*/}
            {/*      <p>Customizable announcement post</p>*/}
            {/*      <p>Limited to 100 comments per raffle</p>*/}
            {/*    </Card.Body>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
            <Col xs="12" md="6" lg="4" className="mx-auto mt-5 align-self-stretch">
              <Card bg="light" border="primary" className="shadow-sm h-100">
                <Card.Body className="text-center">
                  <h3 className="font-weight-light">Monthly</h3>
                  <p className="h3 my-4">$6.99<small className="font-weight-light">/month</small></p>
                  <p className="text-center">
                    <Button data-event="start-now" data-id="monthly-plan" as="a" href="https://app.arbitery.com/?a=1" size="lg">Start Now</Button>
                  </p>
                  <p><small>Cancel risk-free within the first month</small></p>
                  <hr className="mt-5 mb-5" />
                  <p><b>Unlimited</b> draws per month</p>
                  <p>Comment and like picker</p>
                  <p>Customizable announcement page</p>
                  <p>Prize claims</p>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="6" lg="4" className="mx-auto mt-5 align-self-stretch">
              <Card bg="light" className="shadow-sm h-100">
                <Card.Body className="text-center">
                  <div className="d-flex align-items-center justify-content-center mb-2">
                    <h3 className="font-weight-light mr-2 mb-0">Annual</h3>
                    <Badge variant="success" className="align-self-center">SAVE 20%</Badge>
                  </div>
                  <div className="my-3">
                    <p className="h3 m-0">$5.75<small className="font-weight-light">/month</small></p>
                    <small className="text-muted">Billed as one payment of $69</small>
                  </div>
                  <p className="text-center">
                    <Button data-event="start-now" data-id="yearly-plan" as="a" href="https://app.arbitery.com/?a=1" size="lg">Start Now</Button>
                  </p>
                  <p><small>Cancel risk-free within the first month</small></p>
                  <hr className="mt-5 mb-5" />
                  <p><b>Unlimited</b> draws per month</p>
                  <p>Comment and like picker</p>
                  <p>Customizable announcement page</p>
                  <p>Prize claims</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    commentPicker: file(relativePath: { eq: "comment_picker.png" }) {
      childImageSharp {
        fluid(maxWidth: 476) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    andTheWinners: file(relativePath: { eq: "and_the_winners_are.png" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    announcement: file(relativePath: { eq: "announcement_screen.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    faceF: file(relativePath: { eq: "face_f.jpeg" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    faceM: file(relativePath: { eq: "face_m.jpeg" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
