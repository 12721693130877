import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import {FaCaretDown} from "react-icons/fa";

const faqs = {
	instagram: [
		{
			question: 'How to randomly select a winner on Instagram?',
			answer: (
				'Collecting all the entrants manually and checking if the winners follow your account can be tedious and time-consuming. Hence it would help if you used a winner picker like Arbitery to automate the whole process and ensure that the selection is truly random.'
			)
		},
		{
			question: 'How to pick a random comment on Instagram?',
			answer: (
				'If you want to avoid spending hours extracting all the comments from an Instagram post and picking a random comment to announce the winner of your giveaway, you need to make use of our random comment picker. It will automatically select a truly random comment and verify the winners for you.'
			)
		},
		{ question: 'Do I need to give access to my Instagram or Facebook account?', answer: 'No. We don\'t need to have access to any of your social media accounts. You only provide the Instagram post URL or username when you define your giveaway\'s rules in the app.' },
		{
			question: 'How do you make sure that winner selection is really random?',
			answer: (
				<div>
					<p>
						Arbitery uses top-notch technology to generate cryptographic random numbers suitable for use where unbiased results are critical. Similar technology finds application in science, art, statistics, cryptography, gaming, gambling, and other fields.
					</p>
					NO ONE, including Arbitery, can influence the results of the winner selection.
				</div>
			)
		},
		{
			question: 'Do you support Instagram personal accounts?',
			answer: 'Yes. Arbitery supports all types of Instagram accounts including personal, creator and business.'
		},
		{
			question: 'What kind of contest rules does Arbitery support?',
			answer: (
				<div>
					<p>Our application supports the following types of rules:</p>
					<ul>
						<li>Like a post</li>
						<li>Leave a comment</li>
						<li>Post with a hashtag</li>
						<li>Follow an account</li>
					</ul>
					You can combine multiple different rules, which allows you to pick a winner for multiple posts or accounts.
				</div>
			)
		},
		{
			question: 'How long does it take to generate winners?',
			answer: (
				'The process of generating the winners is quite complex. On average, it takes 3-5 minutes to pick the winners. However, the process could take up to 30 minutes and fully depends on the number of rules you specified and the amount of likes/followers/comments that have to be scanned to produce the results.'
			)
		},
	],
	youtube: [
		{
			question: 'How to find a YouTube URL?',
			answer: (
				'Open the YouTube app and find a video you want to use or start watching the video on youtube.com. Tap the Share icon under the video. Click the Copy button to copy a link to the video.'
			)
		},
		{
			question: 'Can I filter out certain comments?',
			answer: (
				'Yes. You can filter out comments based on specific content and consider comments from the same user as one entry.'
			)
		},
		{ question: 'Do I have to give access to my YouTube account?', answer: 'No. We don\'t need to have access to your YouTube account. You only need to provide the YouTube video URL.' },
	]
};

const FaqAccordion = ({ type = 'instagram' }) => {
	const questions = faqs[type];
	return (
		<Accordion>
			{questions.map((faq, index) => (
				<Card>
					<Accordion.Toggle as={Card.Header} eventKey={index.toString()} className="d-flex cursor-pointer align-items-center justify-content-between">
						<h3 className="h6 mb-0">{faq.question}</h3>
						<FaCaretDown />
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={index.toString()}>
						<Card.Body>
							<p>
								{faq.answer}
							</p>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			))}
		</Accordion>
	)
}

export default FaqAccordion;